.overlay {  
  position:fixed;
  z-index:3;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image {
  width: 95%;
  border-radius: 20px;
}
